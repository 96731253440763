import React, { useState } from 'react';
import ReactTooltip from "react-tooltip";
import './App.css';

const logosRvb = [
  "logo-loginline", "logo-loginline-horizontal", "logo-loginline-vertical", "logo-loginline-vertical-allonge"
];

const logosWhite = [
  "logo-loginline-blanc", "logo-loginline-horizontal-blanc", "logo-loginline-vertical-blanc", "logo-loginline-vertical-allonge-blanc"
];

const logosCmjn = [
  "logo-loginline-cmjn", "logo-loginline-horizontal-cmjn", "logo-loginline-vertical-cmjn", "logo-loginline-vertical-allonge-cmjn"
];

const Typos = [
  {
    name: "Nunito",
    link: '<link rel="stylesheet" type="text/css" href="//fonts.googleapis.com/css?family=Nunito" />'
  },
  {
    name: "Spartan",
    link: '<link rel="stylesheet" type="text/css" href="//fonts.googleapis.com/css?family=Spartan" />'
  }
];

const colors = [
  "#4a7fe9",
  "#4b49e9",
  "#8816c1",
  "#9b0d43",
  "#d21d40",
  "#e94014",
  "#e56d13",
  "#140f2d",
];

const gradient = "background-image: linear-gradient(to right, #4a7fe9 0%, #4b49e9 20%, #8816c1 40%, #9b0d43 60%, #d21d40 75%, #e94014 85%, #e56d13 100%);";

function App() {

  const [currentTypo, setCurrentTypo] = useState("");
  const [currentColor, setCurrentColor] = useState("");
  const [isGradient, setIsGradient] = useState(false);

  return (
    <>
      <ReactTooltip id='download'>
        {"Télécharger"}
      </ReactTooltip>
      <ReactTooltip id='copy'>
        {"Copier"}
      </ReactTooltip>
      <header className="App-header">
        <div className="wrapHeader">
          <img src="logo-loginline-vertical.png" className="App-logo" alt="logo" />
          <h1 style={{ color: "#4b49e9", marginRight: "30px", fontFamily: "spartan" }}>
            Charte Graphique
          </h1>
        </div>
        <div className="title">
          <h2 style={{ color: "white" }}>
            Règles d'usage
          </h2>
        </div>
        <div style={{ padding: "64px 180px" }}>
          <p className="usage">
            Les éléments de l’identité visuelle ci-dessous sont destinés à une utilisation par les
            membres internes à Log’in Line, par la presse, ou par nos partenaires.
            Si vous ne faites partie d’aucune de ces catégories et que vous souhaitez utiliser des
            éléments de notre identité visuelle, une autorisation doit-être demandée à la direction
            de Log’in Line. La charte graphique (téléchargeable ci-dessous) définit les règles
            d’utilisation des différents éléments de l’identité visuelle de Log’in Line.
            Il est primordial de respecter ces règles afin de garder une identité visuelle forte
            et cohérente. En aucun cas ces règles ne devront être modifiées sauf cas exceptionnels
            validés par la direction de Log’in Line.
          </p>
        </div>
        <div className="title">
          <h2 style={{ color: "white" }}>
            Logos web
          </h2>
        </div>
        <div style={{ display: 'flex', justifyContent: "space-around", paddingTop: "64px" }}>
          <div>
            <h3 className="subtitle"> Télécharger RVB .png</h3>
            {logosRvb.map(logoRvb =>
              <a href={logoRvb + ".png"} data-tip data-for='download' download>
                <img src={logoRvb + ".png"} className="downloadableLogo" alt={logoRvb} />
              </a>
            )
            }
          </div>
          <div>
            <h3 className="subtitle"> Télécharger RVB .svg</h3>
            {logosRvb.map(logoRvb =>
              <a href={logoRvb + ".svg"} data-tip data-for='download' download>
                <img src={logoRvb + ".svg"} className="downloadableLogo" alt={logoRvb} />
              </a>
            )
            }
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: "space-around", padding: "64px 0" }}>
          <div>
            <h3 className="subtitle">Télécharger blanc .png</h3>
            {logosWhite.map(logoWhite =>
              <a href={logoWhite + ".png"} data-tip data-for='download' download>
                <img src={logoWhite + ".png"} className="downloadableLogo downloadableLogoWhite" alt={logoWhite} />
              </a>
            )
            }
          </div>
          <div>
            <h3 className="subtitle">Télécharger blanc .svg</h3>
            {logosWhite.map(logoWhite =>
              <a href={logoWhite + ".svg"} data-tip data-for='download' download>
                <img src={logoWhite + ".svg"} className="downloadableLogo downloadableLogoWhite" alt={logoWhite} />
              </a>
            )
            }
          </div>
        </div>
        <div className="title">
          <h2 style={{ color: "white" }}>
            Logos d'impression
          </h2>
        </div>
        <div style={{ textAlign: "center", padding: "64px 0" }}>
          <h3 className="subtitle">Télécharger CMJN .png</h3>
          {logosCmjn.map(logoCmjn =>
            <a href={logoCmjn + ".png"} data-tip data-for='download' download>
              <img src={logoCmjn + ".png"} className="downloadableLogo" alt={logoCmjn} />
            </a>
          )
          }
        </div>
        <div className="title">
          <h2 style={{ color: "white" }}>
            Typographie
          </h2>
        </div>
        <div style={{ display: 'flex', justifyContent: "space-around" }}>
          {Typos.map(typo =>
            <div className="downloadableLogo downloadableTypo" style={{ fontFamily: typo.name, display: "flex" }}>
              <a href={typo.name + ".zip"} data-tip data-for='download' download
                style={{ textDecoration: "none", color: "inherit" }}>
                <p>{typo.name + ".zip"}</p>
              </a>
              <p style={{ marginLeft: 20, marginRight: 20 }}>{" | "}</p>
              <p data-tip data-for='copy' style={{ cursor: "pointer" }}
                onClick={() => { navigator.clipboard.writeText(typo.link); 
                setCurrentTypo(typo.link); setCurrentColor(""); setIsGradient(false) }}>
                {"Lien HTML Google Fonts"}
                {currentTypo === typo.link && <img style={{ textAlign: "center", width: 20, height: 20, marginLeft: 10 }} src="thumb.png" alt="thumb" />}
              </p>
            </div>
          )
          }
        </div>
        <div className="title">
          <h2 style={{ color: "white" }}>
            Couleurs
          </h2>
        </div>
        <div style={{ margin: 20, padding: "64px 0" }}>

          <div style={{ display: "flex", justifyContent: "center" }}>
            {
              colors.map(color =>
                <div>
                  <div className="colorImage" style={{ borderRadius: "30px 0px", backgroundColor: color, height: 80, width: 100, margin: 20, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    {currentColor === color &&
                      <img style={{ textAlign: "center", width: 40, height: 40 }} src="paste.png" alt="thumb" />
                    }
                  </div>
                  <p style={{ fontFamily: "spartan", fontSize: 15, cursor: "pointer", margin: 20 }} data-tip data-for='copy'
                    onClick={() => { navigator.clipboard.writeText(color); 
                    setCurrentColor(color); setCurrentTypo(""); setIsGradient(false) }}>
                    {color}
                  </p>
                </div>
              )
            }
          </div>
          <div style={{ display: "flex", justifyContent: "center", marginTop: 64 }}>
            <div className="title" style={{ width: 1100, height: 16 }} />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button className="downloadableLogo btn" data-tip data-for='copy'
              onClick={() => { navigator.clipboard.writeText(gradient); setIsGradient(true); setCurrentColor(""); setCurrentTypo("") }}>
              <p style={{ fontFamily: "spartan", fontSize: 15 }}>
                {"Dégradé : background-image: linear-gradient(to right, #4a7fe9 0%, #4b49e9 20%, #8816c1 40%, #9b0d43 60%, #d21d40 75%, #e94014 85%, #e56d13 100%);"}
                {isGradient &&
                  <img style={{ textAlign: "center", width: 20, height: 20, marginLeft: 5 }} src="thumb.png" alt="thumb" />
                }
              </p>
            </button>
          </div>
        </div>

        <div className="title">
          <h2 style={{ color: "white" }}>
            Charte graphique
          </h2>
        </div>
        <div style={{ textAlign: "center", padding: "64px 0" }}>
          <h3 className="subtitle">Télécharger la charte graphique en .pdf</h3>
          <a href="charteGraphique.pdf" download data-tip data-for='download' download>
            <img src="logo-pdf.png" className="downloadableLogo" alt="downloadable logo rvb" />
          </a>
        </div>
        <div className="title">
          <h2 style={{ color: "white" }}>
            Slogan Log'in Line : "Launching Digital Products"
          </h2>
        </div>
        <div style={{ textAlign: "center", margin: "20px" }}>
        <p style={{ fontFamily: "Spartan", fontSize: "16px" }}><a style={{textDecoration: "none"}} href="https://www.loginline.com/mentions-legales">Mention légales</a></p>
        </div>
      </header>
    </>
  );
}

export default App;